
import React from "react"

import TagexclusiveItemsForm from "../../components/negosyonow/forms/tagexclusiveitemsform"

const subformFields = [
	{
		"subformid": "content",
		"label": "Downloadable Files",
		"table": "nntagexclusivecontent",
		"sort": "nntagexclusivecontent_order",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 999,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Order",
				"field": "nntagexclusivecontent_order",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Title",
				"field": "nntagexclusivecontent_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "File",
				"field": "nntagexclusivecontent_url",
				"subtotalid": "",
				"value": "",
				"input": "file",
				"mode": "normal",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "private.cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "file/exclusive/content", // OPTIONAL: folder for file; no leading and tailing slashes
				"info": "Leave blank for titles"
			},
			{
				"label": "Thumbnail",
				"field": "nntagexclusivecontent_thumbnail",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "normal",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "file/exclusive/content/thumbnail", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "fit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 256,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 256,	// OPTIONAL: Will be required for some imagemode
				"info": "Leave blank for titles"
			},
		],
		"footerlist": [
		]
	}
];


const TagexclusiveFilesPage = ({location}) => {

	return <TagexclusiveItemsForm
				location={location}
				editSubFormFields={subformFields}
			/>

}


export default TagexclusiveFilesPage;
